import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = props => {
  const { data, location } = props
  const siteTitle = data.site.siteMetadata.title

  const renderForm = () => {
    return (
      <form
        style={styles.formContainer}
        name="contact"
        method="POST"
        data-netlify="true"
        action="/contact-sent"
      >
        <input type="hidden" name="form-name" value="contact" />
        <label style={styles.label}>
          <div style={styles.labelText}>Name:</div>
          <input style={styles.input} type="text" name="name" />
        </label>
        <label style={styles.label}>
          <div style={styles.labelText}>Email:</div>
          <input style={styles.input} type="email" name="email" />
        </label>
        <label style={styles.label}>
          <div style={styles.labelText}>Message:</div>
          <textarea style={styles.textArea} name="message" />
        </label>
        <button style={styles.button} type="submit">
          Send
        </button>
      </form>
    )
  }

  return (
    <Layout location={location} title={siteTitle + ": contact"}>
      <SEO
        title="Contact"
        keywords={[`sharnik`, `wojciech ogrodowczyk`, `contact`, `newsletter`]}
      />
      <Bio />
      {renderForm()}
    </Layout>
  )
}

const styles = {
  formContainer: {
    marginBottom: "150px",
  },
  label: {
    margin: "25px 0",
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  labelText: {
    flex: 1,
  },
  input: {
    flex: 3,
    borderRadius: "4px",
    border: "1px solid #bbb",
  },
  textArea: {
    flex: 3,
    height: 200,
    borderRadius: "4px",
    border: "1px solid #bbb",
  },
  button: {
    float: "right",
    borderRadius: "4px",
    border: "1px solid #bbb",
    backgroundColor: "#eee",
    padding: "4px 20px",
  },
}
export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
